@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
    font-family: 'Satoshi';
    src: url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2") format('woff2'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff") format('woff'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf") format('truetype'),
}

.heading_1 {
    color: var(--Text-primary, #212121);
    text-align: center;
    font-family: "Satoshi";
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 2.7rem */
}

.heading_3 {
    color: #0C0C0C;
    font-family: "Lexend" sans-serif;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 2.1rem */
}

.heading_4 {
    color: #FFF;
    font-family: "Satoshi";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 135%;
}


.heading_5 {
    color: var(--text-black, #222);
    font-family: "DM Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 1.5rem */
}

.heading_6 {
    color: var(--text-black, #222);
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.paragraph_1 {
    color: #FFF;
    font-family: "Lexend";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.paragraph_2 {
    color: var(--Gray-900, #101828);
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: -0.022rem
        /* 150% */
}

.label_1 {
    color: var(--Text-primary, #212121);
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.label_2 {
    color: var(--Text-primary, #212121);
    font-family: "Lexend";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    /* 150% */
}

.Label_3 {
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

}

.home_header_txt {
    margin: 0px;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
}

.home_card_txt {
    margin: 0px;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(8, 115, 185);
}

.home_card_txt_count {
    margin: 0px;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    color: rgb(255, 174, 20);
    font-weight: 700;
}

.home_card_2_header_txt {
    margin: 0px;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(100, 100, 100);
    font-weight: 700;
}

.home_card_2_txt {
    margin: 0px;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.page_header {
    color: var(--Gray-1, #333);
    font-family: "DM Sans";
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.3125rem;
    /* 95.455% */
    text-transform: capitalize;
}

.search_txt {
    color: var(--text-secondary, rgba(0, 0, 0, 0.60));
    font-family: "Roboto";
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
}

.table_view_txt {
    color: var(--Primary, #0873B9);
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.022rem;
}

.step_header_txt {
    color: var(--White, #FFF);
    font-family: "DM Sans";
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.125rem;
}

.step_desc_txt {
    color: #C6E1FF;
    font-family: "DM Sans";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.info_desc_card_title_txt {
    color: var(--Gray-800, #1D2939);
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
}

.action_header_txt {
    color: var(--Gray-700, #344054);
    font-family: "DM Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
}

.project_view_company_txt {
    text-transform: capitalize;
    color: var(--Primary, #0873B9);
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.project_view_contact_txt {
    color: var(--FMW-Primary, #0170BD);
    font-family: "DM Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.project_view_time_txt {
    color: var(--FMW-Primary, #0170BD);
    font-family: "DM Sans";
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.03025rem;
}

.project_view_evaluation_header {
    color: var(--text-black, #222);
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.02475rem;
}

.project_view_evaluation_txt {
    color: #1E1E1E;
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.02475rem;
}

.project_view_card_txt {
    color: var(--text-black, #222);
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.011rem;
}

.panel_header {
    color: var(--text-black, #222);
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.01238rem;
}

.panel_txt {
    display: flex;
    color: var(--text-black, #222);
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.panel_txt_header {
    color: var(--text-black, #222);
    font-family: "DM Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.02188rem;
    text-transform: capitalize;
}

.panel_num_txt {
    color: var(--Primary, #0873B9);
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.01238rem;
}

.attach_file_text {
    color: var(--text-black, #222);
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.02475rem;
    text-decoration-line: underline;
}

.fill_details_txt {
    color: var(--Black-Default, #000);
    font-family: "DM Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 1.225rem */
}

.upload_txt {
    color: #000;
    font-family: "DM Sans";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
    /* 123.529% */
}

.proj_tiny_card_header_txt {
    color: var(--Black-Default, #000);
    font-family: "DM Sans";
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 1.3125rem */
}

.pro_approval_txt {
    color: #078247;
    font-family: "DM Sans";
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 1.1375rem */
}

.proj_paid_status_txt {
    color: #4F5A69;
    font-family: "DM Sans";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 1.05rem */
}