.input-phone {
	display: flex;
	width: 100%;
	height: 3.25rem;
	align-items: center;
	border-radius: 3px;
	border: 1px solid #9095a0;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.input-phone:hover {
	border: 2px solid #388E3C47;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.input-phone:focus {
	border: 2px solid #388E3C47;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.input {
	max-width: 100%;
	width: 100%;
	height: 3.25rem;
	align-items: center;
	border-radius: 6px;
	border: 1px solid #212121;

}

.input:hover {
	border: 1px solid #212121;

}

.input:focus {
	border: 1px solid #388E3C47;
}

.input-textarea {
	max-width: 100%;
	width: 100%;
	align-items: center;
	border-radius: 6px !important;
	border: 1px solid #212121 !important;
}

.input-textarea:hover {
	border: 1px solid #212121;
}

.input-textarea:focus {
	border: 1px solid #212121;
}

.input-login {
	width: 100%;
	display: flex;
	padding: 0.625rem 0.8125rem;
	flex-direction: row;
	align-items: center;
	align-self: stretch;
	border-radius: 0.375rem;
	border: 1px solid #D4D4D8;
	background: #FFF;
}

.cstm-btn {
	min-width: 10.75rem;
	width: fit-content;
	padding: 0.625rem 1.25rem;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	border-radius: 2.5rem;
	background: var(--Icon-active, #0170BD);
	color: var(--White, var(--White, #FFF));
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
	/* 150% */
}

.cstm-btn-2 {
	min-width: 15rem;
	width: fit-content;
	padding: 1rem 3.44rem;
	height: 3.4375rem;
	flex-shrink: 0;
	border-radius: 0.375rem;
	background: var(--FMW-Primary, #0170BD);
	color: var(--White, #FFF);
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	/* 1.5rem */
	letter-spacing: -0.011rem;
}

.borderless-btn {
	width: 100%;
	display: flex;
	padding: 0.5625rem 1.25rem;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 624.9375rem;
	background: #fff;
	border: none;
	color: #388E3C;
	text-align: center;
	font-family: "Lexend", sans-serif;
	font-size: 0.875rem !important;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5rem;
}

.border-btn {
	min-width: 10.75rem;
	width: fit-content;
	padding: 0.625rem 1.25rem;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 1.5625rem;
	border: 1px solid #0170BD;
	color: var(--FMW-Primary, #0170BD);
	font-family: "Public Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
}

.border-btn-2 {
	width: 13.5rem;
	height: 3.4375rem;
	flex-shrink: 0;
	border-radius: 0.125rem;
	padding: 1rem 3.25rem;
	border: 1px solid var(--FMW-Primary, #0170BD);
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--FMW-Primary, #0170BD);
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	letter-spacing: -0.011rem;
}


.side-bar-scroll::-webkit-scrollbar-track {
	background-color: transparent;
}

.side-bar-scroll::-webkit-scrollbar-thumb {
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

.logo {
	width: 100%;
	height: 5.375rem;
	flex-shrink: 0;
	object-fit: contain;
}

.slanted-background {
	background-blend-mode: lighten;
	background: url('./assets/img/image1.png');
	background-size: 50% auto, 100% 100%;
	background-repeat: no-repeat;
	background-position: start;
	width: 100%;
	height: 100vh;
	padding-bottom: 3rem;
}

.login_title {
	font-family: "Satoshi";
	font-size: 3rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.custom-file-drop-area {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 0.25rem;
	border: 1px dashed var(--FMW-Primary, #0170BD);
	background: var(--White, #FFF);
	height: 7.8125rem;
	cursor: -webkit-grab;
	cursor: grab;
}

.custom-file-drop-area label {
	color: var(--FMW-Primary, #7E93AE);
	text-align: center;
	font-family: "DM Sans";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: -0.00963rem;
}

.custom-file-drop-area input {
	display: none;
}